import {
  buildTestIds,
  useEnvs,
  useClickOutside,
  cookiePostalCodeStr,
} from "application";
import { useBranchLocation } from "application/context/BranchLocationContext";
import { cookie, shippingApi } from "implementations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ShippingAddressByCityDto } from "typing";
import { theme } from "ui";
import { Form } from "../../../../Form";
import { StateInput } from "./components/StateInput";
import { useGetAddressByCity, useGetAddressByZipCode } from "./hooks";
import { BranchLocationProps, LocationProps } from "./schema";
import {
  ButtonCloseIcon,
  CityTitle,
  Close,
  ConfirmButton,
  ContentCloseIcon,
  CustomInput,
  CustomLink,
  DropdownContainer,
  DropdownContent,
  FormContainer,
  // ClientLocationButton,
  HeaderModal,
  // ActualLocationIcon,
  InfoContainer,
  InfoText,
  InputContainer,
  InterrogationIcon,
  LabelCustom,
  MainContent,
  ModalBranchLocationContainer,
  ModalBranchLocationOverlay,
  TitleModal,
  WrapperCloseIcon,
} from "./styles";

const ModalBranchLocation = () => {
  const [addressBySelectedCity, setAddressBySelectedCity] =
    useState<ShippingAddressByCityDto>({} as ShippingAddressByCityDto);
  const [userZipCode, setUserZipCode] = useState("");
  const allConfigs = useEnvs((state) => state.allConfigs);

  const router = useRouter();

  const {
    isOpen,
    onCloseModalBranchLocation,
    // handleClientLocationByHimCurrentLocation,
    handleLocationBySubmit,
  } = useBranchLocation();

  const { register, handleSubmit, reset, resetField, watch, setValue } =
    useForm<BranchLocationProps>();

  const cityWatching = watch("city");
  const [zipCodeWatching, setZipCodeWatching] = useState(watch("zipCode"));
  const [locationBranchId, setLocationBranchId] = useState(2);
  const [isTypingCity, setIsTypingCity] = useState(false);
  const branchLocationContainerModal = useRef(null);

  const { addressByCityList, conditionErrorToCity, conditionSuccessToCity } =
    useGetAddressByCity(
      cityWatching,
      isTypingCity,
      addressBySelectedCity?.city,
      shippingApi
    );

  const {
    addressByZipCode,
    isZipCodeLengthValid,
    isInvalidZipCode,
    hasZipCodeError,
  } = useGetAddressByZipCode(zipCodeWatching, shippingApi);

  const onAutoCompleteCityByZipCode = useCallback(() => {
    if (isZipCodeLengthValid && addressByZipCode?.city) {
      setValue("city", `${addressByZipCode?.city} - ${addressByZipCode?.uf}`);
      setAddressBySelectedCity({
        city: addressByZipCode?.city,
        uf: addressByZipCode?.uf,
        cep: zipCodeWatching,
        branchId: addressByZipCode?.branchId,
      });
      setLocationBranchId(addressByZipCode?.branchId || 2);
      setUserZipCode(zipCodeWatching);
    }
  }, [addressByZipCode, zipCodeWatching, isZipCodeLengthValid, setValue]);

  const handleClientLocationBySelectedCityAndState = ({
    city: cityAndState,
    zipCode,
  }: LocationProps) => {
    const getZipCode =
      allConfigs?.isAutoLoadShippingInfo === "true" ? userZipCode : zipCode;
    handleLocationBySubmit(getZipCode, cityAndState, locationBranchId, router);
    localStorage?.setItem(cookiePostalCodeStr, getZipCode);
    setAddressBySelectedCity({} as ShippingAddressByCityDto);
    reset();
  };

  const handleOnTypeZipCode = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      let mask = (event.target as HTMLTextAreaElement).value;
      mask = mask
        .replace(/\D/g, "")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{3})\d+?$/, "$1");

      setZipCodeWatching(mask);

      // eslint-disable-next-line no-param-reassign
      (event.target as HTMLTextAreaElement).value = mask;
      setIsTypingCity(false);
    },
    []
  );

  const handleSetIsTypingCity = useCallback(() => {
    setIsTypingCity(true);
  }, []);

  const onSelectCity = (address: ShippingAddressByCityDto) => {
    setValue("city", `${address.city} - ${address.uf}`);
    setIsTypingCity(false);
    resetField("zipCode");
    setUserZipCode(address.cep);
    setAddressBySelectedCity(address);
    setLocationBranchId(address?.branchId || 2);
  };

  useEffect(() => {
    onAutoCompleteCityByZipCode();
  }, [onAutoCompleteCityByZipCode]);

  useClickOutside({
    ref: branchLocationContainerModal,
    callback: () => onCloseModalBranchLocation(cookie),
  });

  const isHadShowButtonConfirm = zipCodeWatching
    ? !isInvalidZipCode && !!addressBySelectedCity.city
    : !!addressBySelectedCity.city;

  const conditionToShowButtonConfirm =
    isHadShowButtonConfirm &&
    cityWatching ===
      `${addressBySelectedCity.city} - ${addressBySelectedCity.uf}`;

  return (
    <ModalBranchLocationOverlay
      isOpen={isOpen}
      {...buildTestIds("modal-branch-location-overlay")}
    >
      <ModalBranchLocationContainer
        {...buildTestIds("modal-branch-location-container")}
        ref={branchLocationContainerModal}
      >
        <ContentCloseIcon>
          <WrapperCloseIcon
            {...buildTestIds("wrapper-close-icon-modal-branch-location")}
          >
            <ButtonCloseIcon
              {...buildTestIds("button-close-modal-branch-location")}
              onClick={() => {
                onCloseModalBranchLocation(cookie);
                reset();
              }}
            >
              <Close alt="Ícone de fechar" />
            </ButtonCloseIcon>
          </WrapperCloseIcon>
        </ContentCloseIcon>
        <HeaderModal {...buildTestIds("header-modal")}>
          <TitleModal {...buildTestIds("title-modal-branch-location")}>
            Por favor, informe sua localização
          </TitleModal>
        </HeaderModal>
        <MainContent {...buildTestIds("main-content-header")}>
          <InfoText {...buildTestIds("info-text-modal-branch-location")}>
            {/* Precisamos de um endereço para te mostrar as melhores ofertas e
            condições comerciais para sua região. */}
            Digite seu CEP ou cidade para ter acesso aos melhores fretes e
            prazos para sua região.
          </InfoText>
          {/* Mudança temporária, a pedido de Peixoto, Volpato e Armando em 17/06/2023 */}
          {/* Obs.: Se em algum momento descomentarmos esse código, quem o fizer, lembrar de adicionar uma lógica na função "handleClientLocationByHimCurrentLocation" para obter o branchId da localização do usuário a partir de uma request feita para um endpoint que retorne a branchId a partir dessa localização (CEP) */}
          {/* <ClientLocationButton
            type="button"
            data-cy="button-location-client-modal-branch-location"
            onClick={() => handleClientLocationByHimCurrentLocation()}
          >
            <ActualLocationIcon />
            Usar localização atual
          </ClientLocationButton> */}
        </MainContent>
        <Form>
          <FormContainer
            {...buildTestIds("form-container-modal-branch-location")}
          >
            <LabelCustom
              htmlFor="zip-code"
              text="Digite seu CEP"
              highlightText
              {...buildTestIds("label-zip-code-modal-branch-location")}
            />
            <CustomInput
              id="zip-code"
              onKeyUp={handleOnTypeZipCode}
              placeholder="_ _ _ _ _-_ _ _"
              type="tel"
              max={9}
              autoComplete="off"
              highlightText
              maxLength={9}
              hasError={hasZipCodeError}
              hasSuccessfully={isZipCodeLengthValid && !hasZipCodeError}
              {...register("zipCode")}
              {...buildTestIds("input-zip-code-modal-branch-location")}
            />
            <StateInput
              conditionError={hasZipCodeError}
              conditionSuccess={isZipCodeLengthValid && !isInvalidZipCode}
              messageError="CEP Inválido"
              {...buildTestIds("state-input-cep-error")}
            />
            <InfoContainer>
              <CustomLink
                {...buildTestIds("custom-link-modal-branch-location")}
                target="_blank"
                href="https://www2.correios.com.br/sistemas/buscacep/buscaCep.cfm"
              >
                <InterrogationIcon alt="Ícone de interrogação" />
                Não sei meu CEP
              </CustomLink>
            </InfoContainer>

            <LabelCustom
              htmlFor="city"
              text="ou, se preferir, insira o nome da sua cidade"
              {...buildTestIds("label-city-modal-branch-location")}
            />
            <InputContainer>
              <CustomInput
                id="city"
                placeholder="Nome da cidade"
                type="text"
                hasError={conditionErrorToCity}
                hasSuccessfully={conditionSuccessToCity}
                hasLargeMarginBottom
                autoComplete="off"
                onKeyUp={handleSetIsTypingCity}
                {...register("city")}
                {...buildTestIds("input-city-modal-branch-location")}
              />
              <DropdownContainer
                isShowDropdown={!!addressByCityList?.length}
                {...buildTestIds("dropdown-container-modal-branch-location")}
              >
                {(addressByCityList?.length as unknown as ShippingAddressByCityDto[])
                  ? addressByCityList?.map((address) => (
                      <DropdownContent
                        {...buildTestIds(
                          "dropdown-content-modal-branch-location"
                        )}
                        onClick={() => {
                          onSelectCity(address);
                        }}
                        key={address.cep}
                        {...buildTestIds(
                          "dropdown-content-modal-branch-location"
                        )}
                      >
                        <CityTitle
                          {...buildTestIds("city-title-modal-branch-location")}
                        >
                          {address.city} - {address.uf}
                        </CityTitle>
                      </DropdownContent>
                    ))
                  : null}
              </DropdownContainer>
              <StateInput
                {...buildTestIds("state-input-city-error")}
                conditionError={conditionErrorToCity}
                conditionSuccess={conditionSuccessToCity}
                messageError="Cidade não encontrada"
              />
            </InputContainer>

            <ConfirmButton
              {...buildTestIds("button-submit-modal-branch-location")}
              onClick={handleSubmit(handleClientLocationBySelectedCityAndState)}
              type={conditionToShowButtonConfirm ? "submit" : "button"}
              {...buildTestIds("button-submit-modal-branch-location")}
              disabled={!conditionToShowButtonConfirm}
            >
              Confirmar
            </ConfirmButton>
          </FormContainer>
        </Form>
      </ModalBranchLocationContainer>
    </ModalBranchLocationOverlay>
  );
};

export { ModalBranchLocation };
