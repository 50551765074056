import { ProfileApi, lazyLoading, useAppContext } from "application";
import { Control, UseFormRegister } from "react-hook-form";
import { ClientAction, IFormValues, SendCodeType } from "typing";
import { CalendarIcon, Column, SpaceWrapper, Text, Title, theme } from "ui";
import { TwoFactorButtonProps, TwoFactorContent } from "../../../2fa";
import { Form, Input, NewCustomInput } from "../../../Form";
import { PasswordValidation } from "../../../PasswordValidation";
import FormHeader from "../Header";
import { PropsToastRegister } from "./components/PropsToastRegister";
import { handleCleanInputData } from "./data";
import {
  BodyContainer,
  CheckboxContainer,
  CheckboxCustom,
  Container,
  CreateAccountButtonContainer,
  Divider,
  EyeCloseIcon,
  EyeOpenIcon,
  FormFooterWrapper,
  FormHeaderContainer,
  LabelCustom,
  LinkText,
  PrivacyPolicyLink,
  SignUpFormButton,
  TwoFactorContainer,
  UserData,
  WhatsAppIcon,
  WrapperDivider,
  WrapperInput,
} from "./styles";
import { useFormBody } from "./useFormBody";
import { useFormInputRefs } from "./useFormInputRefs";

const TwoFactorButton = lazyLoading<TwoFactorButtonProps>(() =>
  import("../../../2fa/Button").then((module) => module.TwoFactorButton)
);

const InputWidth = (input: string) => {
  const { isClientTabletXL, isClientTabletM } = useAppContext();
  const defaultWidth = "100%";
  const widthBigOnTabletXL = "324px";
  const widthSmallOnTabletXL = "279px";

  const defaultWidthOnTabletM = () => {
    return isClientTabletM && defaultWidth;
  };

  const widthBig = () => {
    return isClientTabletXL ? widthBigOnTabletXL : defaultWidth;
  };

  const widthSmall = () => {
    return isClientTabletXL ? widthSmallOnTabletXL : defaultWidth;
  };

  switch (input) {
    case "big":
      return defaultWidthOnTabletM() || widthBig();
    case "small":
      return defaultWidthOnTabletM() || widthSmall();
    default:
      return defaultWidth;
  }
};

type FormBodyProps = {
  profileApi: ProfileApi;
};

const FormBody = ({ profileApi }: FormBodyProps) => {
  const {
    allConfigs,
    showPasswordValidation,
    setShowPasswordValidation,
    typeInputPassword,
    typeInputConfirmPassword,
    setPasswordStrength,
    setFormSubmitted,
    formUnderAction,
    onlyNumbers,
    register,
    handleSubmit,
    setValue,
    setFocus,
    errors,
    isSubmitting,
    passwordWatcher,
    confirmPasswordWatcher,
    acceptedTermsUseWatcher,
    handleManagementInputPasswordType,
    handleManagementInputConfirmPasswordType,
    handleSignUp,
    handleSignUpWithoutTwoFA,
    isDisabled,
    handleFormFieldError,
    isInvalidNameAndSurname,
    isInvalidCpf,
    isInvalidBirthdate,
    isInvalidPhoneNumber,
    isInvalidEmail,
    isInvalidPassword,
    oAuthForm,
    hasDisabledInputEmailByOAuth,
    validateInputValue,
    handleContinueRegister,
    emailWatcher,
    isCodeSent,
    isClientMobile,
    showTwoFactorButton,
    hideFormComponents,
    router,
    control,
  } = useFormBody(profileApi);

  const { emailRef, phoneNumberRef } = useFormInputRefs();

  const focusBirthDate = () => {
    const birthDate = document.querySelector<HTMLInputElement>("#birthdate");
    birthDate?.focus();
  };

  return (
    <BodyContainer>
      <PropsToastRegister
        condition={isSubmitting && !acceptedTermsUseWatcher}
        title="É necessário aceitar os termos de uso e privacidade"
        type="error"
      />
      <FormHeaderContainer isHidden={hideFormComponents}>
        <Title
          color={theme.colors.neutral["525"]}
          fontSize={theme.typography.fontSize.md.x1}
        >
          Crie sua conta
        </Title>
        <FormHeader />
        <WrapperDivider>
          <Divider />
        </WrapperDivider>
      </FormHeaderContainer>
      <SpaceWrapper
        margin={`${theme.space.x0} ${theme.space.x0} ${theme.space.x4} ${theme.space.x0}`}
      >
        <SpaceWrapper
          margin={`${theme.space.x0}`}
          padding={`${theme.space.x0} ${theme.space.x0}`}
        >
          <Form
            id="sign-up-form"
            {...(allConfigs?.isTwoFactorAuthenticationAvailable !== "true" && {
              onSubmit: handleSubmit(handleSignUpWithoutTwoFA),
            })}
          >
            <Container isHidden={hideFormComponents}>
              <UserData>
                <WrapperInput>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="name"
                      text="Nome e Sobrenome"
                      data-cy="label-name"
                    />
                  </Column>
                  <Column>
                    <Input
                      required
                      id="name"
                      name="name"
                      type="text"
                      height="45px"
                      autoComplete="off"
                      placeholder="Digite seu nome e sobrenome"
                      register={
                        register as unknown as UseFormRegister<IFormValues>
                      }
                      hasError={handleFormFieldError("name")}
                      textError={isInvalidNameAndSurname}
                      paddingInfo="0px"
                      paddingErrorMessage="0px"
                      width={InputWidth("big")}
                      margin={`${theme.space.x2} ${theme.space.x0}`}
                      borderRadius={`${theme.space.x1}`}
                      onBlur={({ target }) => setValue("name", target.value)}
                      data-cy="input-name"
                      color={`${theme.colors.neutral["350"]}`}
                      hasSuccessfully={!validateInputValue("name")}
                      borderColor={theme.colors.neutral["580"]}
                      colorInfo={theme.colors.neutral["550"]}
                    />
                  </Column>
                </WrapperInput>
                <WrapperInput>
                  <Column xs="6">
                    <LabelCustom htmlFor="cpf" text="CPF" data-cy="label-cpf" />
                  </Column>
                  <Column>
                    <Input
                      id="cpf"
                      type="tel"
                      mask="999.999.999-99"
                      name="cpf"
                      height="45px"
                      placeholder="Digite seu CPF"
                      hasSuccessfully={!validateInputValue("cpf")}
                      textError={isInvalidCpf}
                      onBlur={({ target }) =>
                        setValue("cpf", target.value.replace(/[^0-9]/g, ""))
                      }
                      colorInfo={theme.colors.neutral["550"]}
                      textInfo={!handleFormFieldError("cpf") && onlyNumbers}
                      margin={`${theme.space.x2} ${theme.space.x0}`}
                      hasError={handleFormFieldError("cpf")}
                      paddingInfo="0px"
                      paddingErrorMessage="0px"
                      data-cy="input-cpf"
                      register={
                        register as unknown as UseFormRegister<IFormValues>
                      }
                      width={InputWidth("small")}
                      borderRadius={`${theme.space.x1}`}
                      borderColor={theme.colors.neutral["580"]}
                    />
                  </Column>
                </WrapperInput>
              </UserData>
              <UserData>
                <WrapperInput ref={emailRef}>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="email"
                      text="E-mail"
                      data-cy="label-email"
                    />
                  </Column>
                  <Column>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      role="presentation"
                      height="45px"
                      autoComplete="off"
                      placeholder="Digite seu e-mail"
                      hasSuccessfully={!validateInputValue("email")}
                      onBlur={({ target }) => {
                        setValue("email", target.value ?? oAuthForm?.email);
                      }}
                      paddingInfo="0px"
                      paddingErrorMessage="0px"
                      hasError={handleFormFieldError("email")}
                      textError={isInvalidEmail}
                      data-cy="input-email"
                      register={
                        register as unknown as UseFormRegister<IFormValues>
                      }
                      disabled={
                        hasDisabledInputEmailByOAuth
                          ? !!oAuthForm?.email
                          : false
                      }
                      width={InputWidth("big")}
                      margin={`${theme.space.x2} ${theme.space.x0}`}
                      borderRadius={`${theme.space.x1}`}
                      borderColor={theme.colors.neutral["580"]}
                      colorInfo={theme.colors.neutral["550"]}
                    />
                  </Column>
                </WrapperInput>
                <WrapperInput ref={phoneNumberRef}>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="phoneNumber"
                      text="Celular"
                      data-cy="label-phoneNumber"
                    />
                  </Column>
                  <Column>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      mask="(99) 99999-9999"
                      placeholder="(81) 98888-9999"
                      register={
                        register as unknown as UseFormRegister<IFormValues>
                      }
                      hasError={false}
                      textError={isInvalidPhoneNumber}
                      width={InputWidth("small")}
                      height="45px"
                      paddingInfo="0px"
                      paddingErrorMessage="0px"
                      textInfo={
                        !handleFormFieldError("phoneNumber") && onlyNumbers
                      }
                      borderRadius={`${theme.space.x1}`}
                      onBlur={({ target }) =>
                        setValue("phoneNumber", target.value.replace(/\D/g, ""))
                      }
                      margin={`${theme.space.x2} ${theme.space.x0}`}
                      hasSuccessfully={!validateInputValue("phoneNumber")}
                      data-cy="input-phoneNumber"
                      borderColor={theme.colors.neutral["580"]}
                      colorInfo={theme.colors.neutral["550"]}
                    />
                  </Column>
                </WrapperInput>
              </UserData>
              <UserData>
                <WrapperInput>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="birthdate"
                      text="Data de Nascimento"
                      data-cy="label-birthdate"
                    />
                  </Column>

                  <NewCustomInput
                    data-cy="input-birthdate"
                    name="birthdate"
                    placeholder="Escolha a data"
                    control={control as unknown as Control}
                    id="birthdate"
                    type="date"
                    hasIcon
                    hasIconBorder
                    icon={<CalendarIcon width={40} />}
                    textInfo={!handleFormFieldError("birthdate")}
                    textError={isInvalidBirthdate}
                    hasError={handleFormFieldError("birthdate")}
                    hasSuccessfully={!validateInputValue("birthdate")}
                    width={isClientMobile ? "100%" : "204px"}
                    paddingInfo="0px"
                    paddingErrorMessage="0px"
                    height="45px"
                    borderColor={theme.colors.neutral["580"]}
                    margin={`${theme.space.x2} ${theme.space.x0}`}
                    padding="1.2rem 2.875rem 1.2rem 0.9rem !important"
                    colorInfo={theme.colors.neutral["550"]}
                    onClickIcon={focusBirthDate}
                    onChange={({ target }) => {
                      setValue("birthdate", target?.value);
                    }}
                  />
                </WrapperInput>
              </UserData>
              <UserData>
                <WrapperInput>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="password"
                      text="Senha"
                      data-cy="label-password"
                    />
                  </Column>
                  <Input
                    id="password"
                    name="password"
                    type={typeInputPassword}
                    role="presentation"
                    autoComplete="off"
                    placeholder="Digite uma senha válida"
                    register={
                      register as unknown as UseFormRegister<IFormValues>
                    }
                    onKeyDown={() => setShowPasswordValidation(true)}
                    height="45px"
                    paddingInfo="0px"
                    paddingErrorMessage="0px"
                    width={InputWidth("big")}
                    textInfo={!handleFormFieldError("password")}
                    borderRadius={`${theme.space.x1}`}
                    hasError={handleFormFieldError("password")}
                    textError={isInvalidPassword}
                    data-cy="input-password"
                    onChange={({ target }) =>
                      setValue("password", target.value)
                    }
                    icon={
                      typeInputPassword === "password" ? (
                        <EyeCloseIcon />
                      ) : (
                        <EyeOpenIcon />
                      )
                    }
                    onClickIcon={() =>
                      handleManagementInputPasswordType(
                        typeInputPassword === "password" ? "text" : "password"
                      )
                    }
                    margin={`${theme.space.x2} ${theme.space.x0}`}
                    padding="1.2rem 2.875rem 1.2rem 0.9rem !important"
                    hasSuccessfully={!validateInputValue("password")}
                    hasIcon
                    borderColor={theme.colors.neutral["580"]}
                    colorInfo={theme.colors.neutral["550"]}
                  />
                </WrapperInput>
                <WrapperInput>
                  <Column xs="6">
                    <LabelCustom
                      htmlFor="password"
                      text="Confirme a senha"
                      data-cy="label-password"
                    />
                  </Column>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={typeInputConfirmPassword}
                    role="presentation"
                    autoComplete="off"
                    height="45px"
                    placeholder="Repita a senha"
                    register={
                      register as unknown as UseFormRegister<IFormValues>
                    }
                    width={InputWidth("small")}
                    hasError={handleFormFieldError("confirmPassword")}
                    paddingInfo="0px"
                    paddingErrorMessage="0px"
                    textInfo={!handleFormFieldError("confirmPassword")}
                    onClickIcon={() =>
                      handleManagementInputConfirmPasswordType(
                        typeInputConfirmPassword === "password"
                          ? "text"
                          : "password"
                      )
                    }
                    icon={
                      typeInputConfirmPassword === "password" ? (
                        <EyeCloseIcon />
                      ) : (
                        <EyeOpenIcon />
                      )
                    }
                    data-cy="input-confirm-password"
                    onChange={({ target }) =>
                      setValue("confirmPassword", target.value)
                    }
                    margin={`${theme.space.x2} ${theme.space.x0}`}
                    hasIcon
                    padding="1.2rem 2.875rem 1.2rem 0.9rem !important"
                    borderRadius={`${theme.space.x1}`}
                    hasSuccessfully={
                      !validateInputValue("confirmPassword") &&
                      confirmPasswordWatcher?.length > 1
                    }
                    borderColor={theme.colors.neutral["580"]}
                    colorInfo={theme.colors.neutral["550"]}
                  />
                </WrapperInput>
              </UserData>
              <PasswordValidation
                onChangePasswordStrength={(strength) =>
                  setPasswordStrength(strength)
                }
                userConfirmPassword={confirmPasswordWatcher}
                userPassword={passwordWatcher}
                showComponent={showPasswordValidation}
              />
              <UserData>
                <CheckboxContainer>
                  <CheckboxCustom
                    id="acceptedTermsUse"
                    checked={!!acceptedTermsUseWatcher}
                    hasError={!!errors.acceptedTermsUse}
                    data-cy="checkbox-accept-form"
                    {...register("acceptedTermsUse", { required: true })}
                  />
                  <Text
                    fontSize={theme.typography.fontSize.sm.x4}
                    color={theme.colors.neutral["750"]}
                    letterSpacing="-0.24px"
                  >
                    Li e aceito os
                    <PrivacyPolicyLink>
                      <LinkText
                        id="termsOfUse"
                        color={theme.colors.neutral["750"]}
                        fontWeight={String(theme.typography.fontWeight["500"])}
                        fontSize={theme.typography.fontSize.sm.x4}
                        href="https://ferreiracosta.movidesk.com/kb/pt-br/article/156348/termos-e-condicoes-de-uso-do-site"
                      >
                        termos e condições de uso
                      </LinkText>{" "}
                      e{" "}
                      <LinkText
                        id="termsOfPrivacy"
                        color={theme.colors.neutral["750"]}
                        fontWeight={String(theme.typography.fontWeight["500"])}
                        fontSize={theme.typography.fontSize.sm.x4}
                        href="https://ferreiracosta.movidesk.com/kb/article/158335/politica-de-privacidade-do-site-da-ferreira-costa"
                      >
                        politica de privacidade
                      </LinkText>{" "}
                    </PrivacyPolicyLink>
                  </Text>
                </CheckboxContainer>
                <CheckboxContainer>
                  <CheckboxCustom
                    id="trackerOrderByWhatsApp"
                    data-cy="checkbox-tracker-order"
                    required={false}
                    hasError={false}
                    {...register("trackerOrderByWhatsApp")}
                  />
                  <WhatsAppIcon />
                  <Text
                    fontSize={theme.typography.fontSize.sm.x3}
                    color={theme.colors.neutral["750"]}
                  >
                    Desejo acompanhar meus pedidos e receber notificações via
                    Whatsapp
                  </Text>
                </CheckboxContainer>
              </UserData>
            </Container>
            <FormFooterWrapper>
              <CreateAccountButtonContainer>
                {allConfigs?.isTwoFactorAuthenticationAvailable === "true" ? (
                  <TwoFactorContainer>
                    <TwoFactorContent
                      options={null}
                      sendCodeType="email"
                      clientAction="createUser"
                      email={emailWatcher}
                      preventDefault
                      phoneNumber={null}
                      viewType={isClientMobile ? "component" : "modal"}
                      canChooseAnotherTwoFactorMethod={false}
                      hasError={isCodeSent === false}
                      isUnderAction={formUnderAction}
                      onClickFailureButton={() => {
                        router.push("/");
                      }}
                      onCallAction={handleSignUp}
                      onClickIsInvalidClientInfo={(
                        clientAction: ClientAction | null,
                        sendCodeType: SendCodeType
                      ) => {
                        handleCleanInputData(
                          clientAction,
                          sendCodeType,
                          emailRef,
                          phoneNumberRef,
                          setValue,
                          setFocus
                        );
                      }}
                    />
                    {showTwoFactorButton && (
                      <TwoFactorButton
                        title="Continuar"
                        isDisabled={!!isDisabled}
                        handleOnClick={handleContinueRegister}
                      />
                    )}
                  </TwoFactorContainer>
                ) : (
                  <SignUpFormButton
                    form="sign-up-form"
                    data-cy="button-create-account"
                    text="Criar conta"
                    textUnderAction="Criando conta..."
                    underAction={formUnderAction}
                    buttonType="submit"
                    onClickAction={() => {
                      setFormSubmitted(true);
                      handleSubmit(handleSignUpWithoutTwoFA);
                    }}
                    isDisabled={!!isDisabled}
                    fontWeight={theme.typography.fontWeight["400"]}
                    color={`${theme.colors.secondary["380"]}`}
                    hoveredColor={`${theme.colors.secondary["350"]}`}
                  />
                )}
              </CreateAccountButtonContainer>
            </FormFooterWrapper>
          </Form>
        </SpaceWrapper>
      </SpaceWrapper>
    </BodyContainer>
  );
};

export default FormBody;
